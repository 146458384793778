<template>
  <div id="session-flow-analytics">
    <!-- <empty-bots v-if="!hasBots && !isLoading"></empty-bots> -->
    <Filters
      type="card"
      :filter-model="filterModel"
      @init="filterChange"
      @on-apply="onApplyFilters"
    />
    <div class="session-flow-analytics">
      <div class="vx-row">
        <vs-col vs-w="12">
          <h2 class="mb-0">
            {{ lang.sessionsFlow.title[this.languageSelected] }}
          </h2>
        </vs-col>
      </div>
      <div class="vx-row pt-4 session-flow">
        <vs-col vs-w="12">
          <vue-pipeline
            v-if="service && service[0]"
            ref="pipeline"
            :x="parseInt(form.x)"
            :y="parseInt(form.y)"
            :data="data"
            :showArrow="form.showArrow"
            :ystep="parseInt(form.ystep)"
            :xstep="parseInt(form.xstep)"
            :lineStyle="form.lineStyle"
            @select="nodeClick"
          />
          <span v-else class="mt-6 mb-6">
            {{ lang.sessionsFlow.text[this.languageSelected] }}
          </span>
        </vs-col>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Vue from 'vue'
import VuePipeline from 'k-vue-pipeline'
import konecta from '@/services/konecta'
const moment = require('moment')
Vue.use(VuePipeline)

export default {
  data() {
    return {
      title: '',
      filterModel: [],
      service: '',
      index: 1,
      nextLevel: 0,
      levels: [],
      info: null,
      aux: '',
      report: null,
      ids: [],
      data: [
        {
          name: 'Start',
          hint: '100%',
          status: 'start',
          next: [],
          id: '',
          index: 0,
          level: -1,
          branches: []
        }
      ],
      form: {
        x: 50,
        y: 55,
        xstep: 100,
        ystep: 100,
        data: 100,
        showArrow: true,
        lineStyle: 'default',
        from: 0,
        to: 0
      },
      interval: {}
    }
  },
  methods: {
    ...mapActions('metrics', ['getReportData']),
    setFilterModel() {
      this.filterModel = [
        {
          name: 'botsNoVersion',
          component: 'BotNoVersionFilter',
          width: 6,
          props: {
            multipleSelect: false,
            allowEmpty: true
          }
        },
        {
          name: 'dates',
          component: 'DateRangeFilter',
          width: 6,
          props: {
            value: this.interval
          }
        }
      ]
    },
    nodeClick(node) {
      try {
        if (
          node.next.length > 1 ||
          (node.next.length == 1 && this.data[node.next[0].index].name != 'end')
        ) {
          let toDelete = []
          this.data.forEach(d => {
            if (toDelete.includes(d.index) || d.index == node.index) {
              d.next.forEach(n => {
                if (d.index == node.index) {
                  if (this.data[n.index].name != 'end') {
                    toDelete.push(n.index)
                  }
                } else {
                  toDelete.push(n.index)
                }
              })
            }
          })
          let deleted = 0
          let changes = []
          toDelete = toDelete.sort(function (a, b) {
            return a - b
          })
          for (let i = this.data.length - 1; i >= 0; i--) {
            if (toDelete.includes(i)) {
              deleted++
              this.index--
            } else {
              let oldIndex = this.data[i].index
              this.data[i].index =
                this.data[i].index - toDelete.length + deleted
              let nodeChange = {
                oldIndex: oldIndex,
                newIndex: this.data[i].index
              }
              changes.push(nodeChange)
            }
          }
          for (let i = this.data.length - 1; i >= 0; i--) {
            let nextToDelete = []
            if (this.data[i].next.length > 0) {
              for (let ind = this.data[i].next.length - 1; ind >= 0; ind--) {
                if (!toDelete.includes(this.data[i].next[ind].index)) {
                  let newIndex = changes.find(
                    e => e.oldIndex == this.data[i].next[ind].index
                  )
                  this.data[i].next[ind].index = newIndex.newIndex
                } else {
                  nextToDelete.push(ind)
                }
              }
              nextToDelete.forEach(td => {
                this.data[i].next.splice(td, 1)
              })
            }
          }
          const reverseToDelete = toDelete.reverse()
          reverseToDelete.forEach(i => {
            this.data.splice(i, 1)
          })
        } else {
          let indexAux = this.index
          let level = []
          let branchId = 0
          let next = []
          this.info.forEach(e => {
            if (
              e.dialogsids.length > node.level + 1 &&
              !level.includes(e.dialogsids[node.level + 1]) &&
              e.dialogsids[node.level] == node.id &&
              node.branches.includes(branchId)
            ) {
              if (e.dialogsids.length - 1 == node.level + 1) {
                let nodeAux = {
                  name: e.dialogsnames[node.level + 1],
                  hint: (1 / this.info.length) * 100 + '%',
                  status: 'success',
                  next: [{ index: this.index + 1, weight: 2 }],
                  id: e.dialogsids[node.level + 1],
                  index: this.index,
                  level: node.level + 1,
                  branches: [branchId]
                }
                if (e.dialogsnames[node.level + 1] == 'takeover') {
                  nodeAux.status = 'paused'
                }
                next.push(this.index)
                level.push(e.dialogsids[node.level + 1])
                this.data.splice(this.index, 0, nodeAux)
                this.index++
                let endNode = {
                  name: 'end',
                  hint: (1 / this.info.length) * 100 + '%',
                  status: 'end',
                  next: [],
                  id: 'end',
                  index: this.index,
                  level: 0,
                  branches: [branchId]
                }
                this.data.splice(this.index, 0, endNode)
                this.index++
              } else {
                let nodeAux = {
                  name: e.dialogsnames[node.level + 1],
                  hint: (1 / this.info.length) * 100 + '%',
                  status: 'success',
                  next: [],
                  id: e.dialogsids[node.level + 1],
                  index: this.index,
                  level: node.level + 1,
                  branches: [branchId]
                }
                if (e.dialogsnames[node.level + 1] == 'takeover') {
                  nodeAux.status = 'paused'
                }
                next.push(this.index)
                level.push(e.dialogsids[node.level + 1])
                this.data.splice(this.index, 0, nodeAux)
                this.index++
              }
            } else if (
              e.dialogsids.length > node.level + 1 &&
              level.includes(e.dialogsids[node.level + 1]) &&
              e.dialogsids[node.level] == node.id &&
              node.branches.includes(branchId)
            ) {
              this.data
                .find(
                  n =>
                    n.level == node.level + 1 &&
                    n.id == e.dialogsids[node.level + 1] &&
                    n.index >= indexAux
                )
                .branches.push(branchId)
              if (e.dialogsids.length - 1 == node.level + 1) {
                if (
                  this.data.find(
                    n =>
                      n.level == node.level + 1 &&
                      n.id == e.dialogsids[node.level + 1] &&
                      n.index >= indexAux
                  ).next.length == 0
                ) {
                  let endNode = {
                    name: 'end',
                    hint: (1 / this.info.length) * 100 + '%',
                    status: 'end',
                    next: [],
                    id: 'end',
                    index: this.index,
                    level: 0,
                    branches: [branchId]
                  }
                  this.data.splice(this.index, 0, endNode)
                  this.data
                    .find(
                      n =>
                        n.level == node.level + 1 &&
                        n.id == e.dialogsids[node.level + 1] &&
                        n.index >= indexAux
                    )
                    .next.push({ index: this.index, weight: 2 })
                  this.index++
                } else {
                  this.data[
                    this.data.find(
                      n =>
                        n.level == node.level + 1 &&
                        n.id == e.dialogsids[node.level + 1] &&
                        n.index >= indexAux
                    ).next[0].index
                  ].branches.push(branchId)
                  this.data[
                    this.data.find(
                      n =>
                        n.level == node.level + 1 &&
                        n.id == e.dialogsids[node.level + 1] &&
                        n.index >= indexAux
                    ).next[0].index
                  ].hint =
                    (this.data[
                      this.data.find(
                        n =>
                          n.level == node.level + 1 &&
                          n.id == e.dialogsids[node.level + 1] &&
                          n.index >= indexAux
                      ).next[0].index
                    ].branches.length /
                      this.info.length) *
                      100 +
                    '%'
                }
              }
              this.data.find(
                n =>
                  n.level == node.level + 1 &&
                  n.id == e.dialogsids[node.level + 1] &&
                  n.index >= indexAux
              ).hint =
                (this.data.find(
                  n =>
                    n.level == node.level + 1 &&
                    n.id == e.dialogsids[node.level + 1] &&
                    n.index >= indexAux
                ).branches.length /
                  this.info.length) *
                  100 +
                '%'
            }
            branchId++
          })
          this.aux = this.data
          next.forEach(n => node.next.push({ index: n, weight: 2 }))
        }
      } catch (ex) {
        this.$vs.notify({
          title: 'Error',
          text: '',
          color: 'danger',
          position: 'top-right'
        })
        console.error(ex)
      }
    },
    onApplyFilters(filters) {
      const filtersTypes = Object.keys(filters)
      filtersTypes.forEach(filter => this.filterChange(filters[filter]))
    },
    parseRange(values) {
      const from = values[0]
      const to = values[1]

      return { from, to }
    },
    filterChange(filter) {
      switch (filter.type) {
        case 'botNoVersion':
          this.service = filter.value[0]
          this.$router.push({ services: this.service })
          break
        case 'date':
          if (filter.value[0] && filter.value[1]) {
            this.interval = this.parseRange(filter.value)
          }
          break
        default:
          break
      }
      this.data = [
        {
          name: 'Start',
          hint: '100%',
          status: 'start',
          next: [],
          id: '',
          index: 0,
          level: -1,
          branches: []
        }
      ]
      this.index = 1
      this.nextLevel = 0
      this.load()
    },
    async getReport() {
      let reportsQuery = 'sessions_flow'
      try {
        const response = await konecta.get(
          '/report?select[reportSection]=true&select[name]=true&select[description]=true&select[reportType]=true&select[_extra]=true&where[reportType][$in]=' +
            reportsQuery
        )
        this.report = response.data[0]
      } catch (error) {
        this.$vs.notify({
          title: 'Error',
          text: 'There was an error loading report services',
          color: 'danger',
          position: 'top-right'
        })
      }
    },
    async load() {
      if (!this.service || this.service.length === 0) {
        return
      }
      const toDateStr =
        this.interval && moment(this.interval.to).format('YYYY-MM-DD HH:mm:ss')
      const fromDateStr =
        this.interval &&
        moment(this.interval.from).format('YYYY-MM-DD HH:mm:ss')

      const dataAux = {
        fullnameLike: this.fullnameLike,
        orderByColumn: this.orderByColumn,
        orderByOrder: this.orderByOrder,
        services: [this.service],
        from: fromDateStr,
        to: toDateStr
      }
      try {
        this.$vs.loading()
        const result = await this.getReportData({
          reportId: this.report._id,
          payload: dataAux
        })
        this.info = result.data[0]
        let level = []
        let branchId = 0
        let next = []
        this.info.forEach(e => {
          if (e.dialogsids.length >= 1 && !level.includes(e.dialogsids[0])) {
            if (e.dialogsids.length == 1) {
              let node = {
                name: e.dialogsnames[0],
                hint: (1 / this.info.length) * 100 + '%',
                status: 'success',
                next: [{ index: this.index + 1, weight: 2 }],
                id: e.dialogsids[0],
                index: this.index,
                level: 0,
                branches: [branchId]
              }
              if (e.dialogsnames[0] == 'takeover') {
                node.status = 'paused'
              }
              next.push(this.index)
              level.push(e.dialogsids[0])
              this.data.splice(this.index, 0, node)
              this.index++
              let endNode = {
                name: 'end',
                hint: (1 / this.info.length) * 100 + '%',
                status: 'end',
                next: [],
                id: 'end',
                index: this.index,
                level: 0,
                branches: [branchId]
              }
              this.data.splice(this.index, 0, endNode)
              this.index++
            } else {
              let node = {
                name: e.dialogsnames[0],
                hint: (1 / this.info.length) * 100 + '%',
                status: 'success',
                next: [],
                id: e.dialogsids[0],
                index: this.index,
                level: 0,
                branches: [branchId]
              }
              if (e.dialogsnames[0] == 'takeover') {
                node.status = 'paused'
              }
              next.push(this.index)
              level.push(e.dialogsids[0])
              this.data.splice(this.index, 0, node)
              this.index++
            }
          } else {
            this.data.find(n => n.id == e.dialogsids[0]).branches.push(branchId)
            this.data.find(n => n.id == e.dialogsids[0]).hint =
              (this.data.find(n => n.id == e.dialogsids[0]).branches.length /
                this.info.length) *
                100 +
              '%'
          }
          branchId++
        })
        next.forEach(n => this.data[0].next.push({ index: n, weight: 2 }))
      } catch (ex) {
        if (ex.message !== 'cancelled') {
          const reportName =
            this.languageSelected === 'es'
              ? this.report.name[0].text
              : this.report.name[1].text
          this.$vs.notify({
            text:
              this.lang.metrics.reportMessages.text[this.languageSelected] +
              `"${reportName}"`,
            color: 'danger'
          })
          console.error(ex)
        }
      } finally {
        this.$vs.loading.close()
      }
    },
    weekInterval() {
      const to = new Date()
      let from = new Date(to.getTime())
      from.setDate(from.getDate() - 7)
      return {
        from: new Date(from.setHours(0, 0, 0, 0)),
        to: new Date(to.setHours(23, 59, 59, 999))
      }
    }
  },

  computed: {
    ...mapState(['lang', 'languageSelected'])
  },
  components: {
    Filters: () => import('../filters/Filters.vue')
  },
  async mounted() {
    this.title = this.lang.sessionsFlow.title[this.languageSelected]
    await this.getReport()
    this.interval = this.weekInterval()
    await this.setFilterModel()
  }
}
</script>

<style lang="scss">
.session-flow-analytics {
  margin: 30px 0px;
  padding: 20px;
  background-color: #fafafa;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  h2 {
    font-size: 18px;
    margin-bottom: 30px;
  }

  .kona-alert {
    margin-top: 0;

    .refresh {
      margin-left: 10px;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
.session-flow {
  overflow-x: auto;
}
</style>
